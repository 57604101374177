<template>
	<div>
		<Payment />
	</div>
</template>

<script>
// @ is an alias to /src
import Payment from '@/components/reports/receipt/Payment.vue'

export default {
	name: 'ReceiptPayment',
	components: {
		Payment,
	},
	data() {
		return {
			
		}
	},
	mounted() {
		
	},
	computed: {
		
	},
	methods: {
		
	},
}
</script>
